'use client';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocale } from 'next-intl';
import { useAtomValue, useAtom } from 'jotai';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui/button';
import { SideActionButtons } from '@/components/lobby/SideActionButtons';
import Image from 'next/image';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { userInfoAtom, cloudflareTurnstileTokenAtom } from '@/store/userInfo';
import { useOverview } from '@/apis/rakings';
import useUserStatus from '@/hooks/useUserStatus';
import useMiniAppColor from '@/hooks/useMiniAppColor';
import usePlayGame from '@/components/ui/PlayGameBtn';

import { isLocalOrBata, isProduction } from '@/utils/checkRuntime';
import { getPublicAsset } from '@/utils/getPublicAsset';
import CountUp from 'react-countup';
import FloatingRings from '@/components/lobby/FloatingRings';
import { EasterEgg } from '@/components/EasterEgg';

import RankingsDialog from '@/components/ui/Rankings';

import { Drawer, DrawerContent, DrawerOverlay } from '@/components/ui/drawer';
import leafImage from '@/public/images/lobby/leaf.png';
import { clsx } from 'clsx';
import { OpenAD } from '@/components/ui/openAD';
import DailyRewards from '@/components/DailyRewards';
import { Notifications } from '@/components/Notification';
import LocalizedLink from '@/components/ui/LocalizedLink';
import MenuButton from '@/components/lobby/MenuButton';
import { motion, AnimatePresence } from 'framer-motion';
import { formatLargeNumber } from '@/utils/number/formatLargeNumber';
import thinkingdata from 'thinkingdata-browser';
import { pageView, track } from '@/utils/thinkdata/track';

const ScoreItem = ({
  icon,
  type,
  value,
  width = 38,
  height = 38,
  imgClassName,
}) => (
  <div className="relative inline-block bg-[#faf9e6] rounded-2xl min-w-[30%]  border-2 divide-solid border-black">
    <Image
      className={`absolute  ${imgClassName}`}
      width={width}
      height={height}
      src={icon}
      alt="item"
    />
    {type === 'coin' ? (
      <span className="pl-9 pr-2 font-bold">
        {value ? formatLargeNumber(value) : ''}
      </span>
    ) : (
      <CountUp
        preserveValue={true}
        className="pl-8 pr-2 font-bold"
        useGrouping={false}
        end={Number(value)}
      />
    )}
  </div>
);

const ScoreBoard = ({ userInfo }) => (
  <div className="flex items-start justify-start space-x-3 ">
    <ScoreItem
      type="pina"
      imgClassName={'-left-[7px] -top-[21px] scale-95'}
      icon={getPublicAsset('/images/lobby/pinaple_sm.png')}
      value={userInfo?.bananaCount}
    />
    <ScoreItem
      type="coin"
      imgClassName={'-left-[7px] -top-[8px] '}
      icon={getPublicAsset('/images/lobby/coins.png')}
      value={userInfo?.gold}
    />
  </div>
);

const RankingInfoItem = ({ title, value }) => (
  <div
    className={`w-full  text-center text-xs bg-[#D1AB74] rounded-[8px] py-[2px] px-2`}
  >
    <div className="whitespace-nowrap stroke-chocolate font-bold text-[#FFF9CA]">
      {title}
    </div>
    <CountUp
      preserveValue={true}
      className="font-bold stroke-lemon"
      useGrouping={false}
      end={Number(value)}
    />
  </div>
);

const RankingInfo = ({ overviewData, forceOpen }) => {
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    setIsOpen(forceOpen);
  }, [forceOpen]);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.div
      className={`${isOpen ? 'pt-[2px] pb-[8px]' : 'py-2'} inline-block text-center space-y-3 px-3  cursor-pointer`}
      style={{
        backgroundImage: `url(${getPublicAsset('/images/lobby/Ranking_bg2.png')})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={toggleOpen}
    >
      <div
        className={`${!isOpen ? 'stroke-lemon' : ''} transition-all font-bold text-sm font-comicbd`}
      >
        Today's
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="space-y-2"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <RankingInfoItem
              title="Challenges "
              value={overviewData?.data?.overviewDTO?.challenges || 0}
            />
            <RankingInfoItem
              title="Completions"
              value={overviewData?.data?.overviewDTO?.passes}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const GameControls = ({ PlayGameButton, handleGameStart }) => {
  const [showRankings, setShowRankings] = useState(false);

  return (
    <>
      <div className={clsx(' flex justify-between items-end w-full px-2 z-30')}>
        {/* left */}
        <div className="absolute left-2 flex flex-col justify-center items-center space-y-4">
          <MenuButton />
          <NavigationButton
            href={`/bag`}
            size={32}
            text="Bag"
            icon={getPublicAsset(`/images/lobby/bag2.png`)}
          />
        </div>
        {/* center */}
        <div className="mx-auto ">{PlayGameButton}</div>
        {/* right */}
        <div className="absolute right-2 flex flex-col justify-center items-center space-y-4">
          <SideButtonsList handleGameStart={handleGameStart} />
          <NavigationButton
            size={40}
            text="Rankings"
            onInteractiveClick={() => setShowRankings(!showRankings)}
            icon={getPublicAsset(`/images/lobby/rankings1.png`)}
          />
        </div>
        {showRankings && (
          <RankingsDialog closeHandler={() => setShowRankings(false)} />
        )}
      </div>
    </>
  );
};

let NavigationButton = ({
  icon,
  size,
  text,
  href,
  ...baseProps
}: {
  href?: string;
  size: number;
  text: string;
  icon: string;
  onInteractiveClick?: () => void;
}) => {
  return (
    <BaseInteractive {...baseProps}>
      <LocalizedLink className="inline-block" href={href} prefetch={true}>
        <div
          className="inline-block w-[55px] h-[55px] active:translate-y-0.5 active:translate-x-0.5 relative"
          style={{
            backgroundImage: `url(${getPublicAsset('/images/lobby/btnbg.png')})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className=" absolute bottom-[5px] left-1/2 -translate-x-1/2 text-center w-full">
            <Image
              width={size}
              height={size}
              src={icon}
              alt="NavigationButton"
              className="inline-block"
            />
            <div className="text-white stroke-black text-[12px]">{text}</div>
          </div>
        </div>
      </LocalizedLink>
    </BaseInteractive>
  );
};

const SideButtonsList = ({ handleGameStart }) => {
  const [easterEggShow, setEasterEggShow] = useState(false);

  return (
    <div className="space-y-4">
      <SideActionButtons
        onInteractiveClick={() => {
          track('open_easterEgg', {});
          setEasterEggShow(true);
        }}
        text="Easter Egg"
        image={getPublicAsset('/images/easter-egg/egg1.png')}
      />
      <SideActionButtons
        text="Dont'bet"
        href="/spin"
        image={getPublicAsset('/images/lobby/turntable.png')}
      />
      <EasterEgg
        show={easterEggShow}
        handleGameStart={handleGameStart}
        onClose={() => setEasterEggShow(false)}
      />
    </div>
  );
};

export default function Home() {
  useMiniAppColor('#60a1da');
  const userInfo = useAtomValue(userInfoAtom);

  const router = useRouter();
  const locale = useLocale();
  const { data: overviewData, isLoading } = useOverview();
  const { data: userState, refetchUserStatus } = useUserStatus();
  const [cloudflareTurnstileToken] = useAtom(cloudflareTurnstileTokenAtom);

  const [forceOpenRankingInfo, setForceOpenRankingInfo] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const halfScreenHeight = windowHeight / 2;

      setForceOpenRankingInfo(scrollPosition < halfScreenHeight);
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    window?.telegramAnalytics?.init({
      token: process.env.NEXT_PUBLIC_TELEGRAM_DATA_CHIEF_TOKEN,
      appName: process.env.NEXT_PUBLIC_APPLICATION_NAME,
    });
    refetchUserStatus();
    router.prefetch(`${locale}/game`);
  }, []);

  useEffect(() => {
    pageView('homepage');
  }, []);

  if (!cloudflareTurnstileToken) {
    return null;
  }

  // if (isProduction && !isMobilePlatform(platform)) {
  //   router.push(`${locale}/playOnMobile`);
  // }

  const { PlayGameButton, handleGameStart, canEnterNextLevel } = usePlayGame();

  return (
    <>
      <div className="relative w-full min-h-[100%] overflow-hidden bg-gradient-to-b from-[#add5f5] to-[#60a1da]">
        <Image className="absolute w-full z-2" src={leafImage} alt={'lobby'} />
        <div className="fixed px-3 mt-3 w-full  top-0 left-0 right-0 z-10">
          <ScoreBoard userInfo={userInfo} />
        </div>
        <div className="fixed px-3 top-[44px] left-0 right-0 z-10 w-full space-y-1 py-1">
          <OpenAD />
          <Notifications />
          <div className="flex justify-between items-start">
            <RankingInfo
              overviewData={overviewData}
              forceOpen={forceOpenRankingInfo}
            />
          </div>
        </div>

        <div className="h-full pt-[10rem] text-center relative flex-center justify-center ">
          <FloatingRings />
        </div>

        <div className="fixed z-20 flex flex-col w-full text-center justify-center items-center bottom-[6rem] duration-500 mb-2 animate-in slide-in-from-bottom">
          <GameControls
            PlayGameButton={PlayGameButton}
            handleGameStart={handleGameStart}
          />
        </div>
      </div>
    </>
  );
}
