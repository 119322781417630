import React, { FC, useState, useEffect } from 'react';
import Image from 'next/image';
import Avatar from 'react-avatar';
import { useTranslations } from 'next-intl';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useMyRanks, useCityUserLists } from '@/apis/rakings';
import { useAtom, useStore } from 'jotai';
import { userInfoAtom } from '@/store/userInfo';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { track } from '@/utils/thinkdata/track';

import './rankings.css';

const redRibbonBg = getPublicAsset('/images/lobby/red-ribbon.png');
const rakingBg = getPublicAsset('/images/lobby/raking-bg.png');
const heartImg = getPublicAsset('/images/lobby/heart.png');
const rankOneImg = getPublicAsset('/images/lobby/one.png');
const rankTowImg = getPublicAsset('/images/lobby/two.png');
const rankThreeImg = getPublicAsset('/images/lobby/three.png');
const decorateImg = getPublicAsset('/images/lobby/decorate.png');
const closeImg = getPublicAsset('/images/lobby/close.png');
const rakingsBtnImg = getPublicAsset('/images/lobby/rakings-icon.png');

const RANK_IMAGE = {
  1: rankOneImg,
  2: rankTowImg,
  3: rankThreeImg,
};

const mapListItem = ({
  rank,
  name,
  headerUrl,
  bananaCount,
  registerCity,
  registerCountry,
}) => {
  return {
    rank,
    avator: headerUrl,
    name,
    passes: bananaCount,
    city: registerCity,
    country: registerCountry,
  };
};

const RegisterCountryName = ({ city, country }) => {
  return (
    <div className="flex flex-col max-w-[118px] truncate text-left ml-2">
      <div className="text-sm font-bold"> {city} </div>
      <div className="text-xs"> {country} </div>
    </div>
  );
};

function RankingsDialog({ closeHandler }: { closeHandler?: () => void }) {
  const t = useTranslations();
  const store = useStore();
  const userInfo = store.get(userInfoAtom);
  const tabs = ['friends', 'cities', 'global'];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  // @ts-ignore
  const [userRank, setUserRank] = useState({
    rank: '0',
    avator: userInfo.headerUrl,
    name: userInfo.name,
    passes: userInfo.bananaCount,
    city: '',
    country: '',
  });
  const [friends, setFriends] = useState([]);
  const [cities, setcities] = useState([]);
  const [global, setGlobal] = useState([]);
  const [list, setList] = useState([]);
  const { data: myRanks, isLoading } = useMyRanks();

  useLockBodyScroll();

  useEffect(() => {
    if (activeTab === tabs[0]) {
      // @ts-ignore
      setList(myRanks?.data?.friendRankings?.map(mapListItem));
      setUserRank({
        ...userRank,
        rank: myRanks?.data?.myFriendRank,
        name: userInfo.name,
        avator: userInfo.headerUrl,
        passes: myRanks?.data?.myBananaCount,
      });
    } else if (activeTab === tabs[1]) {
      // @ts-ignore
      setList(myRanks?.data?.cityUserRankingList?.map(mapListItem));
      setUserRank({
        ...userRank,
        rank: myRanks?.data?.myCityRank,
        name: userInfo.registerCountry,
        city: userInfo.registerCity,
        country: userInfo.registerCountry,
        avator: '',
        passes: myRanks?.data?.cityBananaCount,
      });
    } else if (activeTab === tabs[2]) {
      // @ts-ignore
      setList(myRanks?.data?.globalUserRankingList?.map(mapListItem));
      setUserRank({
        ...userRank,
        rank: myRanks?.data?.myGlobalRank,
        name: userInfo.name,
        avator: userInfo.headerUrl,
        passes: myRanks?.data?.myBananaCount,
      });
    }
  }, [activeTab, isLoading]);

  useEffect(() => {
    track('ranking_check');
  }, []);

  return (
    <div
      className={` fixed flex items-center justifiy-center overscroll-none left-0 top-0 w-screen h-screen font-comicbd z-100`}
    >
      <div
        onClick={closeHandler}
        className="absolute w-full h-full opacity-80 bg-[#e7d9be]"
      />
      <div className="absolute w-[90%] h-[40%] left-[5%] scale-125 pointer-events-none z-20">
        <Image fill src={rakingBg} alt="rakings background" />
      </div>
      <Card
        className="flex flex-col relative bg-[#9d353c] w-10/12 h-[500px] m-auto z-10"
        shadowColor="#9d353c"
      >
        <div className="flex flex-col w-[100%] absolute top-0 left-0 z-30">
          <div className="absolute w-[120%] h-[120px] left-[-10%] top-[-100px] z-30">
            <Image fill src={decorateImg} alt="decorate" />
          </div>
          <div className="relative w-[120%] left-[-10%]">
            <BaseInteractive onInteractiveClick={closeHandler}>
              <div className="closeButton absolute w-[40px] h-[40px] right-4 z-40 active:translate-y-0.5 active:translate-x-0.5">
                <Image fill sizes="80vw" src={closeImg} alt="close" />
              </div>
            </BaseInteractive>
            <div className="absolute w-full h-[80px] top-[0] ">
              <Image fill src={redRibbonBg} alt="Rankings Ribbon" />
            </div>
            <div className="absolute w-full h-[40px] top-[16px] left-0 align-middle text-center  stroke-darkred text-[#ffdf2e] text-2xl font-bold">
              Today's Rankings
            </div>
          </div>
        </div>
        <div className="h-[80px]" />
        <div className="tabs z-30">
          {tabs.map((tab) => {
            const tabColor = tab === activeTab ? '#f25155' : '#fce9be';
            const tabClass = tab === activeTab ? '!translate-y-[-8px]' : '';
            return (
              <Button
                key={tab}
                className={`pb-[24px] text-[#fff] stroke-black capitalize font-bold bg-[${tabColor}] ${tabClass}`}
                onClick={() => {
                  track('ranking_type', {
                    rank_type: tab,
                  });
                  setActiveTab(tab);
                }}
                shadowColor={tabColor}
              >
                {t(tab)}
              </Button>
            );
          })}
        </div>
        <div className="flex flex-col mt-[-24px] text-[#010101] bg-[#fce9be] h-[320px] overflow-x-hidden overflow-y-auto overscroll-contain border-black rounded-md border-2 z-40 py-4 px-2">
          <div className="flex flex-row w-full font-bold px-2 pt-2">
            <div className="w-[48px] text-center capitalize"> {t('rank')} </div>
            <div className="flex-1 text-left capitalize ml-2">
              {activeTab === tabs[1] ? t('city') : t('user')}{' '}
            </div>
            <div className="w-[76px] text-right capitalize"> pinas </div>
          </div>
          {list?.map((item, idx) => {
            return (
              <div
                key={idx}
                className="flex flex-row items-center bg-[#ffd77c] border-black rounded-md border-2 z-20 p-2 mt-2"
              >
                {item.rank < 4 ? (
                  <div className="flex relative w-[48px] items-center justify-center">
                    <div className="absolute w-[40px] align-middle text-[#fff] font-bold stroke-black z-10">
                      {' '}
                      {item.rank}{' '}
                    </div>
                    <div className="absolute w-[40px] h-[40px]">
                      <Image fill src={RANK_IMAGE[item.rank]} alt="rankBg" />
                    </div>
                  </div>
                ) : (
                  <div className="w-[48px] text-center font-bold">
                    {' '}
                    {item.rank}{' '}
                  </div>
                )}
                <div className="flex flex-1 flex-row items-center truncate">
                  {activeTab === tabs[1] ? null : !item.avator &&
                    !!item.name ? (
                    <Avatar round={true} size="32" name={item.name.charAt(0)} />
                  ) : (
                    <div className="relative w-[32px] h-[32px] rounded-full overflow-hidden">
                      <Image fill src={item.avator} alt={item.name} />
                    </div>
                  )}
                  {activeTab === tabs[1] ? (
                    <RegisterCountryName
                      city={item.city}
                      country={item.country}
                    />
                  ) : (
                    <div className="max-w-[128px] truncate text-left font-bold ml-2">
                      {item.name}
                    </div>
                  )}
                </div>
                <div className="truncate w-[64px] text-right font-bold">
                  {' '}
                  {item.passes}{' '}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row w-full items-center justify-center mt-2 mb-2">
          <div className="relative w-[16px] h-[16px] mx-2">
            <Image fill src={heartImg} alt="heart" />
          </div>
          <div className="text-[#fff] capitalize font-bold stroke-black">
            {' '}
            {t('your-ranking')}
          </div>
          <div className="relative w-[16px] h-[16px] mx-2">
            <Image fill src={heartImg} alt="heart" />
          </div>
        </div>
        <div className="flex flex-row items-center bg-[#ffc94e] border-black rounded-md border-2 z-30 p-2 mx-2">
          <div className="w-[48px] text-center font-bold">{userRank.rank}</div>
          <div className="flex flex-1 flex-row items-center">
            {activeTab === tabs[1] ? null : !userRank.avator &&
              !!userRank.name ? (
              <Avatar round={true} size="32" name={userRank.name.charAt(0)} />
            ) : (
              <div className="relative w-[32px] h-[32px] rounded-full overflow-hidden">
                <Image fill src={userRank.avator} alt={userRank.name} />
              </div>
            )}
            {activeTab === tabs[1] ? (
              <RegisterCountryName
                city={userRank.city}
                country={userRank.country}
              />
            ) : (
              <div className="truncate text-left ml-2 font-bold">
                {userRank.name}
              </div>
            )}
          </div>
          <div className="truncate w-[48px] text-right font-bold">
            {userRank.passes}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default RankingsDialog;
