import { useUtils } from '@telegram-apps/sdk-react';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { getPublicAsset } from '@/utils/getPublicAsset';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';

const TELEGRAM_CHANNEL = process.env.NEXT_PUBLIC_TELEGRAM_CHANNEL;

let MenuButton = () => {
  const [show, setShow] = useState(() => {
    const storedValue = localStorage.getItem('menuShow')
    return !storedValue ? true : storedValue === 'true'
  })

  useEffect(() => {
    if (show) {
      setShow(false)
      const timer = setTimeout(() => {
        setShow(true)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [])

  const handleToggle = () => {
    setShow(prevShow => {
      const newShow = !prevShow
      localStorage.setItem('menuShow', String(newShow))
      return newShow
    })
  }

  const utils = useUtils();

  return (
    <div className='relative w-full'>
      <div className={`  bottom-[100%] w-full space-y-4 mb-2  transition-all duration-300 py-4 bg-[#80ACD0]/80 rounded-full overflow-hidden ${show ? 'max-h-[300px] ' : 'max-h-[120px]   '}`}>

        <Image onClick={handleToggle}
          className={`${show ? 'rotate-180' : ''} transition-all duration-500   mx-auto    `} width={28} height={28} src={getPublicAsset(`/images/lobby/arrow.png`)} alt='arrow' />


        {[{
          text: 'Seetings',
          icon: getPublicAsset(`/images/lobby/icon-setting.png`),
          href: '/settings'
        }, {
          text: 'How to Play',
          icon: getPublicAsset(`/images/lobby/icon-howToPlay.png`),
          onclick: () => {
            window.open(
              'https://www.youtube.com/watch?v=-t2gRS5rovg',
              '_blank',
              'noopener,noreferrer',
            );
          }
        }, {
          text: 'Ann',
          icon: getPublicAsset(`/images/lobby/icon-ann.png`),
          onclick: () => {
            utils.openTelegramLink(TELEGRAM_CHANNEL);
          }
        }].map((item, index) => (
          <BaseInteractive key={item.text} onInteractiveClick={item.onclick}>
            <LocalizedLink
              prefetch={true}
              href={item.href}
              key={item.text}
              className={`transition-all duration-300 px-[1px] block`}
              style={{
                opacity: show || index === 0 ? 1 : 0,
                transform: show || index === 0 ? 'translateY(0)' : 'translateY(-20px)',
                transitionDelay: show || index === 0 ? `${index * 100}ms` : '0ms',
                pointerEvents: show || index === 0 ? 'auto' : 'none'
              }}
            >
              <Image
                width={40}
                height={40}
                className=' mx-auto'
                src={item.icon}
                alt={item.text}
              />
              <div className='text-white stroke-darkCyan text-xs -mt-[13px]'>{item.text}</div>
            </LocalizedLink>
          </BaseInteractive>
        ))}





      </div>

    </div>
  )
}

export default MenuButton;