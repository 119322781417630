import React, { useState, useEffect, useMemo, useRef } from 'react';
import Image from 'next/image';
import useWebSocket from 'react-use-websocket';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { store } from '@/store';
import { tokenManager } from '@/store/userInfo';
import { useAtom } from 'jotai';
import DeviceFingerprinting from 'device-fingerprinting';
import { clsx } from "clsx"
import { isLocalOrBata } from '@/utils/checkRuntime';

import { useMutation } from '@tanstack/react-query';
import { fetchPinaReward } from '@/apis/rakings';
import useInterval from '@/hooks/useInterval';

type Notification = {
  avatar: string;
  name: string;
  pinaName: string;
  value: number;
  level: number;
};

const formatTimeAgo = (finishTime: string) => {
  const now = new Date();
  const finish = new Date(finishTime);
  const diffInSeconds = Math.floor(
    (now.getTime() + now.getTimezoneOffset() * 60 * 1000 - finish.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else {
    const months = Math.floor(diffInSeconds / 2592000);
    return `${months} month${months > 1 ? 's' : ''} ago`;
  }
};

const _mockData = [
  {
    userName: 'SOXZ44 LOCAL TEST',
    userHeadUrl: '',
    name: 'Moon',
    level: '5',
    usdt: '0.01',
    finishTime: '2024-08-10 14:16:04',
  },
];

const getShowTimeByUsdt = (value: string) => {
  if (!value) return 0;
  const u = Number(value);
  if (isNaN(u)) return 0;
  if (u <= 0.01) return 0;
  if (u <= 1) return 0.5 * 1000;
  if (u <= 10) return 1 * 1000;
  if (u <= 100) return 2 * 1000;
  if (u <= 200) return 5 * 1000;
  if (u <= 500) return 10 * 1000;
  return 3 * 1000;
};

const MAX_NOTIFICATIONS = 100;

export const Notifications: React.FC = () => {
  const [pinaRewards, setPinaRewards] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(100);
  const [token, ] = useAtom(tokenManager, { store: store });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [animationPlaying, setAnimationPlaying] = useState(false);
  const notificationRef = useRef(null);

  const { sendJsonMessage } = useWebSocket(process.env.NEXT_PUBLIC_WEB_SOCKET_URL, {
    onOpen: () => {
      console.log('open', token);
      sendJsonMessage({
        "app": 1,
        "token": token,
        //github.com/KhemnarMayuresh/device-fingerprinting
        "deviceUid": DeviceFingerprinting().getDeviceFingerprint(),
        "command": 1,
      }, false);
    },
    onMessage: (message) => {
      console.log('message', message);
      if (message.data === 'pong') return;
      try {
        const { data } = JSON.parse(message.data);
        if (!data) return;
        if (data.command !== 7) return;
        if (pinaRewards.length < 2 * MAX_NOTIFICATIONS) {
          setPinaRewards([...pinaRewards, data]);
        }
      } catch(err) {
        console.error(err);
      }
    },
    onError: (event) => {
      console.log('error', event);
    },
    heartbeat: {
      message: "ping",
      returnMessage: "pong",
      timeout: 60 * 1000,
      interval: 30 * 1000,
    }
  });

  const { mutate: getPinaReward } = useMutation({
    mutationFn: fetchPinaReward,
    onSuccess: ({ data }) => {
      setPinaRewards(data?.list);
      // setPinaRewards(_mockData);
    },
    onError: ({ data }: any) => {
      throw new Error(JSON.stringify(data));
    },
  });

  const reset = () => {
    setPinaRewards([]);
    setCurrentNotification(null);
    setCurrentIndex(0);
  };

  if (isLocalOrBata) {
    useInterval(() => {
      setPinaRewards([...pinaRewards, { ..._mockData[0] }]);
    }, 10 * 1000);
  }

  useEffect(() => {
    if (animationPlaying) return;
    if (!currentNotification) {
      return setCurrentNotification(pinaRewards[currentIndex]);
    }
    if (currentIndex < pinaRewards.length) {
      return setCurrentIndex(currentIndex + 1);
    }
  }, [pinaRewards]);

  useEffect(() => {
    if (pinaRewards[currentIndex]) {
      setCurrentNotification(pinaRewards[currentIndex]);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (animationPlaying) return;
    if (pinaRewards.length >= MAX_NOTIFICATIONS) {
      reset();
    }
  }, [animationPlaying]);

  // useEffect(() => {
  //   getPinaReward();
  // }, []);

  const nextNotificationOrStop = () => {
    if (currentIndex < pinaRewards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setAnimationPlaying(false);
    }
  };

  useEffect(() => {
    if (!currentNotification) return;
    const showTime = getShowTimeByUsdt(currentNotification?.usdt);
    const scrollSpeed = 40;

    let startTime: number;
    let animationFrameId: number;
    let position = 100;
    // time of left to right animation
    const playTime = 16 * position / (scrollSpeed * 16) * 1000;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;

      if (!notificationRef) return;

      const _p = position - (scrollSpeed * 16) / 1000;
      const translateX = _p < -100 ? -100 : _p;

      if (elapsed < playTime ||
          elapsed > (showTime + playTime) ||
          translateX > 0) {
        notificationRef.current.style.transform = `translateX(${translateX}%)`;
        position = _p;
      }

      if (_p < -100) {
        nextNotificationOrStop();
      } else {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    setAnimationPlaying(true);
    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [currentNotification]);

  const invisible = !currentNotification || !animationPlaying;

  return (
    <div className={clsx('', { 'invisible': invisible })}>
      <div
        className={`w-[100%] h-[35px] relative overflow-hidden`}
        style={{
          background:
            'linear-gradient(to right,rgba(90, 83, 64, 0), rgba(90, 83, 64, 0.9),rgba(90, 83, 64, 0))',
        }}
      >
        <div
          ref={notificationRef}
          style={{
            position: 'absolute',
            whiteSpace: 'nowrap',
          }}
        >
          <div className="flex items-center justify-center h-[35px] px-2">
            <Image
              src={getPublicAsset('/images/game/notification.png')}
              alt={'notification'}
              width={22}
              height={22}
              className="mr-2"
            />
            {/* <Image
              src={currentNotification.userHeadUrl}
              alt={currentNotification.userName}
              width={24}
              height={24}
              className="mr-2 rounded-full"
            /> */}
            <p className="text-white text-xs font-bold">
              {
                currentNotification ? (
                  <>
                    {currentNotification.userName || 'Someone'} got a{' '}
                    <span className="text-[#FADC60]">
                      {currentNotification.name} Pina
                    </span>{' '}
                    worth{' '}
                    <span className="text-[#FADC60]">{currentNotification.usdt}</span>{' '}
                    USDT {formatTimeAgo(currentNotification.finishTime)}!
                  </>
                ) : null
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
